<template>
  <div class="used-materials" v-if="isShowComputation">
    <h2 class="details-title">
      <span>{{ $t('message.result.usedMaterials.table.title') }}</span>
      <button class="details__button-modal" @click="isModalOpen = true"></button>
    </h2>
    <div class="used-materials__table">
      <div class="table-head">
        <div class="titles">
          <div class="titles__left">
            <div class="title">
              {{ $t('message.result.usedMaterials.table.material') }}
            </div>
          </div>
          <div class="titles__right">
            <div class="title">
              {{ $t('message.result.usedMaterials.table.count') }} <br />
              <span class="description">
                {{ $t('message.result.usedMaterials.table.computation') }}
              </span>
            </div>
            <div class="title">
              {{ $t('message.result.usedMaterials.table.count') }} <br />
              <span class="description description--md">
                {{ $t('message.result.usedMaterials.table.package') }}
              </span>
              <span class="description description--xs">
                {{ $t('message.result.usedMaterials.table.packageMob') }}
              </span>
            </div>
            <div class="title title--md">
              {{ $t('message.result.usedMaterials.table.sectors') }}
            </div>
            <div class="title title--xs">
              {{ $t('message.result.usedMaterials.table.sectorsMob') }}
            </div>
          </div>
        </div>
      </div>
      <div class="table-body">
        <div
          class="table-body__item"
          :class="{ active: currentTableItemExpand === `table-item-${i}` }"
          v-for="(item, i) in filteredComputation"
          :key="i"
        >
          <div class="table-body__item-left">
            <div class="content">{{ item.name }}</div>
          </div>
          <div class="table-body__item-right">
            <div class="content">{{ item.count }} {{ item.units }}</div>
            <div class="content">{{ item.countPackage }} {{ item.units }}</div>
            <div class="content count">{{ item.indices.length }}</div>
            <button
              class="chevron"
              :class="{ 'chevron--active': currentTableItemExpand === `table-item-${i}` }"
              @click="toggleTableItem(`table-item-${i}`)"
            >
              <img src="@/assets/img/icons/table-chevron.svg" alt="↓" />
            </button>
          </div>
          <Result-used-materials-nav
            v-if="currentTableItemExpand === `table-item-${i}`"
            :indices="item.indices"
          />
        </div>
      </div>
      <div
        class="table-footer"
        :class="{ active: isShowMore }"
        @click="isShowMore = !isShowMore"
        v-if="computation.length > 7"
      >
        <div class="table-footer__content">
          <div class="text">
            <span
              v-text="
                isShowMore
                  ? `${$t('message.result.usedMaterials.table.hide')}`
                  : `${$t('message.result.usedMaterials.table.show')}`
              "
            ></span>
            {{ computation.length }}
          </div>
          <img src="@/assets/img/icons/filter--select.svg" alt="↓" class="chevron" />
        </div>
      </div>
    </div>
    <Disclaimer :defaultValue="disclaimer" @changeOption="changeDisclaimer($event)"></Disclaimer>
    <modal ref="modal" :is-modal-open="isModalOpen" @close="closeModal()">
      <template #body>
        <article class="related-materials">
          <h3 class="related-materials__title">{{ $t('message.relateMaterials.title') }}</h3>
          <ul class="related-materials__list">
            <li v-for="item of detectors" :key="item.id" class="related-materials__item">
              <h4 class="item__title">{{ item.title }}</h4>
              <span class="item__name">{{ item.name }}</span>
              <p class="related-materials__toggler">
                <toggler :isEnabled="item.status" :id="item.id" @handle="changeStatus($event, item.id)" />
              </p>
            </li>
          </ul>
        </article>
      </template>
    </modal>
  </div>
</template>

<script>
import ResultUsedMaterialsNav from '@/components/smart/Result/ResultUsedMaterialsNav'
import { mapActions, mapState } from 'vuex'
import Disclaimer from '@/components/dump/Disclaimer'
import Modal from '../../elements/Modals/Modal.vue'
import Toggler from '../../elements/Dom/Toggler.vue'
export default {
  data: () => ({
    currentTableItemExpand: null,
    isShowMore: false,
    isModalOpen: false
  }),
  components: {
    Modal,
    ResultUsedMaterialsNav,
    Disclaimer,
    Toggler
  },
  methods: {
    ...mapActions({
      updateDisclaimer: 'updateDisclaimer',
      updateRelatedProducts: 'updateRelatedProducts',
      getResults: 'getResults'
    }),
    toggleTableItem(val) {
      this.currentTableItemExpand = this.currentTableItemExpand === val ? null : val
    },
    changeDisclaimer(text) {
      this.updateDisclaimer(text)
    },
    changeStatus(evt, id) {
      this.updateRelatedProducts({ id: id, status: evt.value })
    },
    closeModal() {
      this.isModalOpen = false
      this.getResults(this.$i18n.locale)
    }
  },
  computed: {
    ...mapState({
      result: state => state.result,
      relatedProducts: state => state.relatedProducts
    }),
    isShowComputation() {
      return (
        Object.prototype.hasOwnProperty.call(this.result, 'total') &&
        this.result.total.computation.length > 0
      )
    },
    detectors() {
      return this.relatedProducts.map((item) => ({
        title: this.$t('message.relateMaterials.titleDetectors'),
        ...item
      }))
    },
    computation() {
      return this.result.total.computation
    },
    filteredComputation() {
      if (this.isShowMore) {
        return this.computation
      }
      return this.computation.slice(0, 7)
    },
    disclaimer() {
      return this.result.disclaimer
    }
  }
}
</script>

<style lang="sass" scoped>
@import "~@/assets/sass/result-used-materials.sass"
.details
  &-title
    display: flex
    align-items: center
    justify-content: space-between
    gap: rem(10)
  &__button-modal
    display: flex
    width: rem(20)
    height: rem(20)
    border: none
    background-color: transparent
    background-image: url(~@/assets/img/icons/layer--parent-menu.svg)
    background-repeat: no-repeat
    background-position: center
    background-size: cover
    cursor: pointer
    &:hover
      background-image: url(~@/assets/img/icons/layer--parent-menu--hover.svg)
.related-materials
  padding: rem(35) 0 rem(25)
  &__title
    margin: 0 rem(35) rem(35)
  &__list
    padding: 0
    margin: 0
    list-style: none
  &__item
    display: grid
    grid-template-columns: 80% 20%
    grid-template-areas: 'title toggler' 'name toggler'
    @extend %16
    .item__title
      grid-area: title
      padding: rem(7) rem(10) 0 rem(35)
      margin: 0
    .item__name
      grid-area: name
      padding: 0 rem(10) rem(7) rem(35)
  &__toggler
    display: flex
    grid-area: toggler
    margin: 0
    .custom-toggler
      width: 100%
      display: flex
      justify-content: center
      align-items: center
</style>
