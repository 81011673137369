<template>
  <div class="used-materials" v-if="isShowTotalMaterials">
    <div class="details-title">
      {{ $t('message.result.usedMaterials.table.titleBySectors') }}
    </div>
    <div
      class="used-materials__table used-materials__table--total"
      v-for="(item, i) in materialsBySector"
      :key="`table-${i}`"
    >
      <div
          class="table-head"
          @click="toggleTableItem(i)"
          :class="{ active: isTableItemExpanded(i) }">
        <span class="title">{{ getSectorName(i) }}</span>
        <span
          class="chevron"
          :class="{ 'chevron--active': isTableItemExpanded(i) }"
        >
          <img src="@/assets/img/icons/table-chevron.svg" alt="↓" />
        </span>
      </div>
      <div v-if="isTableItemExpanded(i)">
        <details-big-table
            :placement="'system'"
            :computation="getTotalSystemMaterialBySector(i)"
            :additional="true"
        ></details-big-table>
        <details-simple-table
            v-if="getTotalJunctionMaterialBySector(i)"
            :computation="getTotalJunctionMaterialBySector(i)"
            :additional="true"
        ></details-simple-table>
      </div>
    </div>
    <div v-if="showRelatedRoofMaterials" class="used-materials__table used-materials__table--total">
      <div
          class="table-head"
          @click="toggleTableItem(relatedRoofMaterialsIndex)"
          :class="{ active: isTableItemExpanded(relatedRoofMaterialsIndex) }"
      >
        <span class="title">{{ $t('message.relateMaterials.title') }}</span>
        <span class="chevron" :class="{ 'chevron--active': isTableItemExpanded(relatedRoofMaterialsIndex) }" >
          <img src="@/assets/img/icons/table-chevron.svg" alt="↓" />
        </span>
      </div>
      <div v-if="isTableItemExpanded(relatedRoofMaterialsIndex)">
        <details-simple-table
            :computation="relatedRoofMaterials"
            :additional="false"
            :title="$t('message.relateMaterials.title')"
            :hasQuantity="false"
        ></details-simple-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import DetailsBigTable from '@/components/smart/Details/DetailsBigTable'
import DetailsSimpleTable from '@/components/smart/Details/DetailsSimpleTable'
export default {
  data: () => ({
    expandedTableItems: {},
    currentTableItemExpand: null
  }),
  components: {
    DetailsSimpleTable,
    DetailsBigTable
  },
  methods: {
    getSectorName(index) {
      return this.allSectors[index].name
    },
    toggleTableItem(index) {
      const key = `table-sector-${index}`
      this.expandedTableItems[key] = !this.expandedTableItems[key]
      this.expandedTableItems = { ...this.expandedTableItems }
    },
    isTableItemExpanded(index) {
      return this.expandedTableItems[`table-sector-${index}`]
    },
    getTotalSystemMaterialBySector(index) {
      return this.result.total.materials[index].system
    },
    getTotalJunctionMaterialBySector(index) {
      return this.result.total.materials[index].junction
    }
  },
  computed: {
    ...mapState({
      result: state => state.result,
      allSectors: state => state.sectors,
      relatedProducts: state => state.relatedProducts
    }),
    materialsBySector() {
      return this.result.total.materials
    },
    isShowTotalMaterials() {
      return (
        Object.prototype.hasOwnProperty.call(this.result, 'total') &&
        this.result.total.materials.length > 0
      )
    },
    relatedRoofMaterialsIndex() {
      return this.allSectors.length + 1
    },
    relatedRoofMaterials() {
      const relatedProductsIds = this.relatedProducts.map(item => parseInt(item.id))

      return [
        { name: this.$t('message.relateMaterials.titleDetectors'),
          materials: [
            { name: '', materials: this.result.total.computation.filter(product => relatedProductsIds.includes(product.id)) }
          ]
        }
      ]
    },
    showRelatedRoofMaterials() {
      return this.relatedProducts.filter((item) => item.status).length
    }
  }
}
</script>

<style scoped lang="sass">
@import "~@/assets/sass/result-used-materials.sass"
</style>
